<template>
  <div>
    <a-button class="button-margin-left btn-rounded" size="large" type="primary" @click.prevent.stop="openModal">Edit FAQ</a-button>

    <a-modal :centered="true" title="Edit FAQ" width="1000px" :visible="modalVisible" ok-text="Save" @ok="attemptSave"
      :ok-button-props="{
        props: { disabled: (isSaving || !canSave) }
      }" v-model="modalVisible">

      <loading-screen :is-loading="isSaving"></loading-screen>

      <a-form layout="vertical" class="update-faq-form">
        <!-- Area -->
        <a-form-item label="Area (e.g. App or Web or Announcements, etc)">
          <a-input v-model="faqLocal.area" size="large"></a-input>
        </a-form-item>
        <!-- / Area -->

        <!-- Question -->
        <a-form-item label="Question">
          <a-input v-model="faqLocal.question" size="large"></a-input>
        </a-form-item>
        <!-- / Question -->

        <!-- Answer -->
        <a-form-item label="Answer">
          <a-textarea v-model="faqLocal.answer" :rows="4" placeholder=""></a-textarea>
        </a-form-item>
        <!-- / Answer -->

        <a-row :gutter="20" class="bottom-row">
          <a-col :span="12">
            <!-- MoreHelp -->
            <a-form-item label="More Help (Hyperlink to a help page/blog/etc)">
              <a-input v-model="faqLocal.moreHelp" size="large"></a-input>
            </a-form-item>
            <!-- / MoreHelp -->
          </a-col>
          <a-col :span="12">
            <!-- Youtube -->
            <a-form-item label="Youtube">
              <a-input v-model="faqLocal.youtube" size="large"></a-input>
            </a-form-item>
            <!-- / Youtube -->
          </a-col>
        </a-row>


        <!-- Published checkbox -->
        <a-form-item class="published-form-item">
          <a-checkbox v-model="faqLocal.published">Published</a-checkbox>
        </a-form-item>
      </a-form>


    </a-modal>
  </div>
</template>

<script>
import LoadingScreen from '../../components/LoadingScreen.vue';
import faqs from '../../api/faqs';

export default {
    props: ['faq'],

  components: { LoadingScreen },

  data() {
    return {
      modalVisible: false,
      isSaving: false,

      faqLocal: {
        id: null,
        published: true,
        area: '',
        question: '',
        answer: '',
        moreHelp: '',
        youtube: ''
      }
    }
  },

  created() {
    this.faqLocal = { ...this.faq }; 
  },

  computed: {
    canSave() {
      return (
        this.faqLocal.area.trim().length
        && this.faqLocal.question.trim().length
        && this.faqLocal.answer.trim().length
      );
    }
  },

  methods: {
    openModal() {
      this.faqLocal = { ...this.faq }; 
      this.modalVisible = true;
    },

    closeModal() {
      this.modalVisible = false;
    },

    attemptSave() {
      let vm = this;
      vm.isSaving = true;
      faqs.updateFaq({
        ...vm.faqLocal
      }).then(() => {
        vm.isSaving = false;
        vm.$message.success('FAQ updated');
        vm.$emit('faq-updated');
        vm.closeModal();
      }).catch(e => {
        vm.isSaving = false;
        console.log(e);
        vm.$message.error('Error updating FAQ');
      })
    }
  }
}
</script>

<style lang="scss">
.update-faq-form {
  .ant-form-item {
    padding-bottom: 0 !important;
    margin-bottom: 25px !important;
  }

  .bottom-row {
    .ant-form-item {
      // margin-bottom: 5px !important;
    }
  }

  .published-form-item {
    margin-bottom: 0 !important;
  }
}
</style>