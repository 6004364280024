<template>
    <div class="faq-item" @click.prevent="toggle">
        <div class="top">
            <div class="left">
                <h3>{{ faq.question }}</h3>
            </div>
            <div class="right">
                <svg :class="{ 'expanded': expanded, 'not-expanded': !expanded }" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 7.5L18.5 14L17.09 15.41L12 10.33L6.91 15.41L5.5 14L12 7.5Z" fill="#1E1E1E" />
                </svg>
            </div>
        </div>
        <div v-if="expanded" class="bottom">
            <p>{{ faq.answer }}</p>

            <!-- More help button -->
            <div class="more-help-wrapper" v-if="faq.moreHelp">
                <a-button class="btn-rounded" size="large" type="primary"
                    @click.prevent.stop="openUrl(faq.moreHelp)">Learn More</a-button>
            </div>
            <!-- / More help button -->

            <!-- Youtube -->
            <div class="more-help-wrapper" v-if="faq.youtube">
                <a-button class="btn-rounded" size="large" type="primary"
                    @click.prevent.stop="openUrl(faq.youtube)">YouTube</a-button>
            </div>
            <!-- / Youtube -->

            <div v-if="canEditFaqs" class="edit-faq-button-wrapper">
                <edit-faq-button-and-modal @faq-updated="handleFaqUpdated" :faq="faq"></edit-faq-button-and-modal>
            </div>
        </div>
    </div>
</template>

<script>
import EditFaqButtonAndModal from './EditFaqButtonAndModal.vue';
import { mapGetters } from 'vuex';

export default {
    components: { EditFaqButtonAndModal },
    props: ['faq'],
    data() {
        return {
            expanded: false
        }
    },
    computed: {
        ...mapGetters('superAdmin', {
            canEditFaqs: 'canEditFaqs'
        })
    },
    methods: {
        toggle() {
            this.expanded = !this.expanded;
        },

        handleFaqUpdated() {
            this.$emit('faq-updated');
        },

        openUrl(url) {
            window.open(url, '_blank');
        }
    }
}
</script>

<style lang="scss">
.faq-item {
    background: #fff;
    cursor: pointer;
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 15px;

    h3 {
        margin-bottom: 0;
    }

    .top {
        display: flex;

        .left {
            flex-grow: 1;
            display: flex;
            align-items: center;
        }

        .right {
            flex-shrink: 1;
            display: flex;
            align-items: center;

            svg {
                transition: transform 0.2s ease-in-out;
            }

            svg.expanded {
                transform: rotate(180deg);
            }

            svg.not-expanded {
                transform: rotate(90deg);
            }
        }
    }

    .bottom {
        margin-top: 15px;
    }

    p {
        margin-bottom: 0;
        white-space: break-spaces;
    }

    .more-help-wrapper {
        margin-top: 25px;
    }

    .edit-faq-button-wrapper {
        margin-top: 25px;
    }
}
</style>