<template>
    <div class="faqs">

        <!-- Page Header -->
        <div class="page-header">
            <h1 class="page-title">FAQs</h1>
            <div class="actions">
                <!-- Search -->
                <a-input-search style="width: 400px;" size="large" placeholder="Search FAQs" v-model="searchQuery" />
                <!-- / Search -->
                <add-faq-button-and-modal class="button-margin-left" v-if="canEditFaqs"
                    @faq-added="loadFaqs"></add-faq-button-and-modal>
            </div>
        </div>
        <!-- / Page Header -->

        <!-- Is Loading -->
        <div class="loader" v-if="isLoading">
            <a-spin></a-spin>
        </div>
        <!-- / Is Loading -->

        <!-- No faqs -->
        <a-alert v-if="!isLoadingCombined && faqsFiltered.length == 0" type="info" message="No FAQs to show" />
        <!-- / No faqs -->

        <!-- FAQs -->
        <div class="faq-list" v-if="!isLoading && faqsFiltered.length">

            <div v-for="(faqsFiltered, area) in faqsFilteredGroupedByArea" :key="`area-${area}`" class="area">
                <h2 @click.prevent="toggleAreaCollapsed(area)">
                    {{ area }}
                    <a-icon style="font-size: 13px;" v-if="shouldShowArea(area)" type="down" />
                    <a-icon style="font-size: 13px;" v-else type="up" />
                </h2>
                <div v-if="shouldShowArea(area)">
                    <faq :faq="faq" v-for="(faq) in faqsFiltered" @faq-updated="loadFaqs" :key="`faq-${faq.id}`"></faq>
                </div>
            </div>

        </div>
        <!-- / FAQs -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddFaqButtonAndModal from './Faqs/AddFaqButtonAndModal.vue';
import Faq from './Faqs/Faq.vue';
import _ from 'lodash';
export default {
    data() {
        return {
            searchQuery: '',
            faqToEdit: null,

            collapsedAreas: []
        }
    },

    computed: {
        ...mapGetters('superAdmin', {
            canEditFaqs: 'canEditFaqs'
        }),

        ...mapGetters('missionControl', {
            userPresence: 'userPresence'
        }),


        ...mapGetters('faqs', {
            isLoading: 'isLoading',
            faqs: 'faqs'
        }),

        faqsFiltered() {
            let faqs = this.faqs;

            // Only get those that are published
            faqs = faqs.filter(faq => faq.published);

            return faqs.filter(faq => {
                return faq.question.toLowerCase().includes(this.searchQuery.toLowerCase()) || faq.answer.toLowerCase().includes(this.searchQuery.toLowerCase());
            });
        },

        faqsFilteredGroupedByArea() {
            return _.groupBy(this.faqsFiltered, 'area');
        }
    },

    components: { AddFaqButtonAndModal, Faq },

    created() {
        // this.loadFaqs();
        if (this.$route.query.q) {
            this.searchQuery = this.$route.query.q;
        }
    },

    methods: {
        ...mapActions('faqs', {
            loadFaqs: 'loadFaqs'
        }),

        toggleAreaCollapsed(index) {
            if (this.collapsedAreas.includes(index)) {
                this.collapsedAreas = this.collapsedAreas.filter(i => i !== index);
            } else {
                this.collapsedAreas.push(index);
            }
        },

        shouldShowArea(index) {
            return !this.collapsedAreas.includes(index);
        }
    }
}
</script>

<style scoped lang="scss">
.faqs {
    padding-bottom: 30px;
}

.button-margin-left {
    margin-left: 10px;
}

.area {
    margin-bottom: 50px;

    h2 {
        margin-bottom: 20px;
        cursor: pointer;
    }

    &:last-child {
        margin-bottom: 0 !important;
    }
}
</style>